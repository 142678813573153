#form {
    text-align: center;
    margin-top: 10%;
    display: block;
}

#form h1 {
    font-size: 64px;
    margin-bottom: 40px;
}

form {
    display: block;
    text-align: left;
    margin: 0 auto;
    width: 50%;
    max-width: 600px;
    /* Limit width for very large screens */
}

form input,
form textarea,
form button {
    display: block;
    margin: 10px auto;
    /* Center horizontally */
    width: calc(100% - 20px);
    /* Adjust for margins */
    padding: 10px;
    font-size: 18px;
    box-sizing: border-box;
    /* Ensure padding is included in width */
}

form textarea {
    height: 150px;
    resize: vertical;
    /* Allow vertical resizing */
}

form label {
    display: block;
    margin: 10px 0 5px;
    font-size: 18px;
    text-align: left;
    /* Ensure labels are aligned left */
}

form button {
    margin: 40px auto;
    /* Center horizontally */
    padding: 10px 20px;
    width: 50%;
    /* Adjust width for button */
    height: 60px;
    color: white;
    background-color: #333333;
    font-size: 18px;
    cursor: pointer;
}


/* Media Queries */


/* For screens smaller than 768px (mobile devices) */

@media (max-width: 768px) {
    #form {
        margin-top: 20px;
    }
    #form h1 {
        font-size: 48px;
        margin-bottom: 30px;
    }
    form {
        width: 80%;
        max-width: 100%;
    }
    form button {
        width: 100%;
        margin-top: 20px;
    }
}


/* For screens between 769px and 1024px (tablets) */

@media (min-width: 769px) and (max-width: 1024px) {
    form {
        width: 70%;
    }
}


/* For screens larger than 1024px (desktops) */

@media (min-width: 1025px) {
    form {
        width: 50%;
    }
}