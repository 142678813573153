#header {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 1000;
    /* zajistí, že hlavička bude nad ostatním obsahem */
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    /* zmenšení paddingu pro lepší zobrazení na menších zařízeních */
}

#logo {
    margin-left: 10px;
    font-size: 30px;
    font-family: "Righteous", sans-serif;
}

ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

li {
    margin-right: 40px;
    /* zmenšení mezery mezi odkazy pro menší obrazovky */
}

.nav-link {
    text-decoration: none;
    color: inherit;
    font-size: 16px;
    font-weight: 600;
    transition: color 0.2s ease;
}

.nav-link:hover {
    color: inherit;
}

.nav-link.inactive {
    color: #a8a8a8;
}

#logo-wrapper {
    text-decoration: none;
    color: inherit;
}


/* Media Queries pro různé velikosti obrazovek */


/* Pro obrazovky menší než 768px (mobilní zařízení) */

@media (max-width: 768px) {
    #header {
        position: static;
    }
    header {
        flex-direction: column;
        position: static;
        align-items: flex-start;
        padding: 10px;
        /* změna z fixní pozice na statickou */
    }
    ul {
        flex-direction: column;
        width: 100%;
    }
    li {
        margin-right: 0;
        margin-bottom: 10px;
    }
    .nav-link {
        font-size: 18px;
    }
    #logo {
        font-size: 22px;
        margin-bottom: 10px;
    }
}


/* Pro obrazovky mezi 769px a 1024px (tablety) */

@media (min-width: 769px) and (max-width: 1024px) {
    header {
        padding: 20px;
    }
    ul {
        justify-content: center;
    }
    li {
        margin-right: 20px;
    }
    .nav-link {
        font-size: 18px;
    }
    #logo {
        font-size: 26px;
    }
}