/* Global settings for #backgroundtext2 */
#backgroundtext2 {
  text-align: center;
  font-size: 100px;
  color: white !important;
  margin-bottom: 20px;
  z-index: 1;
}

/* col */
.col {
  width: 80%;
  margin: 5% auto;
  text-align: left;
}

.col h3 {
  font-size: 32px;
  margin-bottom: 10px;
  color: #ffffff; /* Změněno na bílou pro lepší kontrast */
}

.col span {
  display: block;
  font-size: 20px;
  color: #cccccc; /* Jemná světle šedá */
}

.col h5 {
  margin-top: 15px;
  line-height: 1.5;
  font-size: 24px;
  font-weight: 300;
  color: #aaaaaa; /* Tmavší šedá pro popisy */
}

.col p {
  margin-top: 20px;
  font-size: 24px;
  font-weight: 300;
  color: #dddddd; /* Jemná světle šedá */
}

/* Media Queries */

/* Pro obrazovky menší než 768px (mobilní zařízení) */
@media (max-width: 768px) {
  #backgroundtext2 {
    font-size: 32px;
    text-align: center;
    margin: 20px 0;
    color: black;
  }
  .col {
    width: 90%;
    margin: 10% auto;
  }
  .col h3 {
    font-size: 18px;
  }
  .col span {
    font-size: 16px;
  }
  .col h5,
  .col p {
    font-size: 14px;
  }
}

/* Pro obrazovky mezi 769px a 1024px (tablety) */
@media (min-width: 769px) and (max-width: 1024px) {
  #backgroundtext2 {
    font-size: 100px;
    margin-bottom: 30px;
    color: black;
  }
  .col {
    width: 85%;
    margin: 7% auto;
  }
  .col h3 {
    font-size: 24px;
  }
  .col span {
    font-size: 18px;
  }
  .col h5,
  .col p {
    font-size: 18px;
  }
}

/* Pro obrazovky větší než 1024px (stolní počítače) */
@media (min-width: 1025px) {
  #backgroundtext2 {
    font-size: 100px;
    margin-bottom: 50px;
    color: black;
  }
  .col {
    width: 70%;
    margin: 5% auto;
  }
  .col h3 {
    font-size: 32px;
  }
  .col span {
    font-size: 20px;
  }
  .col h5,
  .col p {
    font-size: 24px;
  }
}
