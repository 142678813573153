#text {
    width: 100%;
    margin-top: 15%;
    padding: 150px;
    text-align: left;
    box-sizing: border-box;
}

#text h1 {
    padding: 0;
    margin: 0;
    font-size: 180px;
    font-weight: normal;
    color: #BFBFBF;
}

#text h4 {
    color: black;
    font-size: 40px;
    font-weight: 300;
}


/* Media Queries pro různé velikosti obrazovek */


/* Pro obrazovky menší než 768px (mobilní zařízení) */

@media (max-width: 768px) {
    #text {
        margin-top: 20%;
        padding: 20px;
        text-align: center;
    }
    #text h1 {
        font-size: 48px;
    }
    #text h4 {
        font-size: 24px;
    }
}


/* Pro obrazovky mezi 769px a 1024px (tablety) */

@media (min-width: 769px) and (max-width: 1024px) {
    #text {
        margin-top: 18%;
        padding: 50px;
        text-align: center;
    }
    #text h1 {
        font-size: 96px;
    }
    #text h4 {
        font-size: 32px;
    }
}


/* Pro obrazovky větší než 1024px (stolní počítače) */

@media (min-width: 1025px) {
    #text {
        margin-top: 15%;
        padding: 150px;
        text-align: left;
    }
    #text h1 {
        font-size: 180px;
    }
    #text h4 {
        font-size: 40px;
    }
}