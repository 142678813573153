#showcase {
    width: 100vw;
    margin-top: 16%;
    position: relative;
    /* zajištění, že showcase zůstane ve vztahu k viewportu */
}

#showcasetext {
    padding-left: 5%;
    width: 55%;
    font-size: 64px;
    font-family: "Gilroy", sans-serif;
    font-weight: 300;
}

span {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 0.25em;
    text-decoration-color: #00397C;
}

#decor-text {
    margin-left: auto;
    padding-right: 2%;
    padding-top: 2%;
    width: 60%;
    font-size: 120px;
    font-weight: bold;
    margin-top: 10%;
    color: #EFEFEF;
}


/* Media Queries pro různé velikosti obrazovek */


/* Pro obrazovky menší než 768px (mobilní zařízení) */

@media (max-width: 768px) {
    #showcase {
        margin-top: 20%;
    }
    #showcasetext {
        padding-left: 3%;
        width: 90%;
        font-size: 32px;
        text-align: center;
    }
    #decor-text {
        width: 90%;
        font-size: 60px;
        text-align: center;
        padding-right: 0;
        margin-top: 5%;
    }
}


/* Pro obrazovky mezi 769px a 1024px (tablety) */

@media (min-width: 769px) and (max-width: 1024px) {
    #showcase {
        margin-top: 18%;
    }
    #showcasetext {
        padding-left: 4%;
        width: 75%;
        font-size: 48px;
    }
    #decor-text {
        width: 75%;
        font-size: 80px;
        margin-top: 8%;
    }
}


/* Pro obrazovky větší než 1024px (stolní počítače) */

@media (min-width: 1025px) {
    #showcase {
        margin-top: 16%;
    }
    #showcasetext {
        padding-left: 5%;
        width: 55%;
        font-size: 64px;
    }
    #decor-text {
        width: 60%;
        font-size: 120px;
        margin-top: 10%;
    }
}