/* Background Text 3 */
#backgroundtext3 {
  margin-top: 20%;
  font-size: 100px;
  color: white;
  text-align: center;
  margin-bottom: 50px;
}

/* Text Section 3 */
#text3 {
  margin: 0 auto;
  width: 60%;
  text-align: center;
  font-size: 36px;
  font-weight: 300; /* Lehčí váha písma */
  line-height: 1.5;
}
.button,
.button2 {
  text-decoration: none;
  color: white;
  background-color: #333333;
  padding: 15px 30px;
  font-size: 20px;
  border: none;
  display: block;
  margin: 30px auto;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease,
    background-color 0.3s ease;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

.button:hover,
.button2:hover {
  transform: translateY(-5px); /* Lift the button */
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.3); /* Stronger shadow */
  background-color: #555555; /* Change color */
  cursor: pointer;
}

/* Active state */
.button:active,
.button2:active {
  transform: translateY(0); /* Button returns to original position */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Reset shadow */
}

/* Background Text 4 */
#backgroundtext4 {
  margin-top: 20%;
  text-decoration: none;
  font-size: 100px;
  color: white;
  text-align: center;
  margin-bottom: 50px;
}

/* Text Section 4 */
#text4 {
  text-decoration: none;
  margin: 0 auto;
  width: 60%;
  text-align: center;
  font-size: 36px;
  font-weight: 300; /* Lehčí váha písma */
  line-height: 1.5;
}

/* Button 2 */
.button2 {
  color: white;
  background-color: #333333;
  padding: 15px 30px;
  font-size: 20px;
  border: none;
  display: block;
  margin: 30px auto 50px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.button2:hover {
  background-color: #555555;
  cursor: pointer;
}

/* Responsive Styles */

/* For screens smaller than 768px (mobile devices) */
@media (max-width: 768px) {
  #backgroundtext3,
  #backgroundtext4 {
    font-size: 32px;
    margin-bottom: 20px;
  }

  #text3,
  #text4 {
    width: 90%;
    font-size: 18px;
  }

  .button,
  .button2 {
    width: 80%;
    font-size: 16px;
    padding: 10px 20px;
  }
}

/* For screens between 769px and 1024px (tablets) */
@media (min-width: 769px) and (max-width: 1024px) {
  #backgroundtext3,
  #backgroundtext4 {
    font-size: 150px;
  }

  #text3,
  #text4 {
    width: 80%;
    font-size: 24px;
  }

  .button,
  .button2 {
    width: 60%;
    font-size: 18px;
  }
}
