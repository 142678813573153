/* AboutText.css */


/* Základní stylování */

#abouttext {
    margin-left: 10%;
    width: 100%;
    margin-top: 15%;
    box-sizing: border-box;
}

.abouttext-header {
    font-size: 96px;
    font-weight: bold;
    margin-bottom: 10px;
}

.abouttext-description {
    font-weight: 300;
    line-height: 1.2;
    font-size: 36px;
}


/* Media Queries pro různé velikosti obrazovek */


/* Pro obrazovky menší než 768px (mobilní zařízení) */

@media (max-width: 768px) {
    #abouttext {
        margin-left: 5%;
        width: 85vw;
        margin-top: 20%;
        text-align: left;
    }
    .abouttext-header {
        font-size: 48px;
    }
    .abouttext-description {
        font-size: 18px;
    }
}


/* Pro obrazovky mezi 769px a 1024px (tablety) */

@media (min-width: 769px) and (max-width: 1024px) {
    #abouttext {
        margin-left: 10%;
        width: 80vw;
        margin-top: 18%;
        text-align: left;
    }
    .abouttext-header {
        font-size: 72px;
    }
    .abouttext-description {
        font-size: 24px;
    }
}


/* Pro obrazovky větší než 1024px (stolní počítače) */

@media (min-width: 1025px) {
    #abouttext {
        margin-left: 20%;
        width: 50%;
        margin-top: 15%;
        text-align: left;
    }
    .abouttext-header {
        font-size: 96px;
    }
    .abouttext-description {
        font-size: 36px;
    }
}