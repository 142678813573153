body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Gilroy', sans-serif;
    width: 100vw;
    padding: 0;
    overflow-x: hidden;
}

* {
    padding: 0;
    margin: 0;
}