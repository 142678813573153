footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100vw;
    margin-top: 400px;
    padding-top: 200px;
    padding-bottom: 100px;
    padding-left: 10%;
    box-sizing: border-box;
    border-top: 1px solid #DDDDDD;
}

#left,
#right {
    width: 100%;
    padding: 10px;
}

#right {
    padding-left: 0;
}

#right h6 {
    font-size: 24px;
    font-weight: 300;
}

#left h1 {
    font-size: 64px;
}

#left h6 {
    font-size: 32px;
    font-weight: 300;
    margin-top: 35%;
}

#right h1 {
    font-size: 32px;
}

footer ul {
    list-style: none;
    display: block;
    margin-bottom: 30%;
    padding: 0;
}

footer li {
    margin-bottom: 10px;
}

.footer-link {
    color: inherit;
    text-decoration: none;
    font-size: 40px;
    font-weight: bold;
    transition: color 0.2s ease;
}

.footer-link:hover {
    color: inherit;
}

.footer-link.inactive {
    color: #a8a8a8;
}


/* Media Queries pro různé velikosti obrazovek */


/* Pro obrazovky menší než 768px (mobilní zařízení) */

@media (max-width: 768px) {
    footer {
        width: 100vw;
        flex-direction: column;
        padding: 50px 5%;
        align-items: center;
    }
    #left h1 {
        font-size: 48px;
        text-align: center;
    }
    #left h6 {
        font-size: 24px;
        margin-top: 20px;
        text-align: center;
    }
    #right {
        padding-left: 0;
        text-align: center;
        width: 100%;
    }
    #right h1 {
        font-size: 24px;
    }
    #right h6 {
        font-size: 18px;
    }
    footer ul {
        margin-bottom: 20%;
        padding: 0;
        text-align: center;
    }
    .footer-link {
        font-size: 24px;
    }
}


/* Pro obrazovky mezi 769px a 1024px (tablety) */

@media (min-width: 769px) and (max-width: 1024px) {
    footer {
        width: 100vw;
        flex-direction: column;
        padding: 100px 5%;
        align-items: center;
    }
    #left h1 {
        font-size: 56px;
        text-align: center;
    }
    #left h6 {
        font-size: 28px;
        margin-top: 30px;
        text-align: center;
    }
    #right {
        padding-left: 0;
        text-align: center;
        width: 100%;
    }
    #right h1 {
        font-size: 28px;
    }
    #right h6 {
        font-size: 22px;
    }
    footer ul {
        margin-bottom: 25%;
        padding: 0;
        text-align: center;
    }
    .footer-link {
        font-size: 32px;
    }
}


/* Pro obrazovky větší než 1024px (stolní počítače) */

@media (min-width: 1025px) {
    footer {
        flex-direction: row;
        padding: 200px 10% 100px 10%;
    }
    #left,
    #right {
        flex: 1;
        padding: 10px;
    }
    #right {
        padding-left: 30%;
    }
    #right h6 {
        font-size: 24px;
    }
    #left h1 {
        font-size: 64px;
    }
    #left h6 {
        font-size: 32px;
        margin-top: 35%;
    }
    #right h1 {
        font-size: 32px;
    }
    footer ul {
        margin-bottom: 30%;
    }
    .footer-link {
        font-size: 40px;
    }
}