@font-face {
    font-family: 'Righteous';
    src: url('./assets/fonts/Righteous-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy';
    src: url('./assets/fonts/Gilroy-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('./assets/fonts/Gilroy-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('./assets/fonts/Gilroy-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('./assets/fonts/Gilroy-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('./assets/fonts/Gilroy-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}