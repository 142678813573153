.card-container {
    position: relative;
    margin: 10% auto;
    max-width: 65%;
}

.card-link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-decoration: none;
    z-index: 1;
}

.content-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 6%;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 20px;
    transition: transform 0.3s ease;
    position: relative;
    z-index: 0;
}

#content {
    flex: 1;
    max-width: 60%;
    transition: transform 0.3s ease;
}

#content h3 {
    font-size: 64px;
    margin: 0;
}

.description {
    font-weight: 300;
    font-size: 36px;
    margin-top: 50%;
    transition: transform 0.15s ease;
}

.content-wrapper img {
    position: absolute;
    bottom: 0;
    right: 6%;
    height: auto;
    max-width: 45%;
    transition: transform 0.15s ease;
}

#mobileapp {
    position: absolute;
    top: 7%;
    right: 6%;
    height: auto;
    max-width: 40%;
    transition: transform 0.15s ease;
}

.card-container:hover .content-wrapper {
    transform: scale(1.02);
}

.card-container:hover #content,
.card-container:hover .description,
.card-container:hover img {
    transform: scale(1.02);
}


/* Media Queries pro různé velikosti obrazovek */


/* Pro obrazovky menší než 768px (mobilní zařízení) */

@media (max-width: 768px) {
    .card-container {
        margin: 20% auto;
        max-width: 90%;
    }
    .content-wrapper {
        flex-direction: column;
        align-items: center;
        padding: 4%;
    }
    #content {
        max-width: 100%;
        text-align: center;
    }
    #content h3 {
        font-size: 36px;
    }
    .description {
        font-size: 18px;
        margin-top: 10%;
    }
    .content-wrapper img,
    #mobileapp {
        position: relative;
        max-width: 80%;
        margin-top: 20px;
        right: 0;
        bottom: 0;
        top: 0;
    }
}


/* Pro obrazovky mezi 769px a 1024px (tablety) */

@media (min-width: 769px) and (max-width: 1024px) {
    .card-container {
        margin: 15% auto;
        max-width: 80%;
    }
    .content-wrapper {
        flex-direction: column;
        align-items: center;
        padding: 5%;
    }
    #content {
        max-width: 100%;
        text-align: center;
    }
    #content h3 {
        font-size: 48px;
    }
    .description {
        font-size: 24px;
        margin-top: 20%;
    }
    .content-wrapper img,
    #mobileapp {
        position: relative;
        max-width: 60%;
        margin-top: 20px;
        right: 0;
        bottom: 0;
        top: 0;
    }
}


/* Pro obrazovky větší než 1024px (stolní počítače) */

@media (min-width: 1025px) {
    .card-container {
        margin: 10% auto;
        max-width: 65%;
    }
    .content-wrapper {
        flex-direction: row;
        align-items: flex-start;
        padding: 6%;
    }
    #content {
        max-width: 60%;
        text-align: left;
    }
    #content h3 {
        font-size: 64px;
    }
    .description {
        font-size: 36px;
        margin-top: 50%;
    }
    .content-wrapper img {
        position: absolute;
        bottom: 0;
        right: 6%;
        height: auto;
        max-width: 45%;
    }
    #mobileapp {
        position: absolute;
        top: 7%;
        right: 6%;
        height: auto;
        max-width: 40%;
    }
}