/* Global settings for #backgroundtext */
#backgroundtext {
  text-align: center;
  font-size: 100px;
  color: #ffffff; /* Bílá pro kontrast s pozadím */
  margin-bottom: 10px;
  z-index: 1;
}

/* col */
.col {
  width: 80%;
  margin: 5% auto;
  text-align: left;
}

.col h3 {
  font-size: 32px;
  margin-bottom: 10px;
  color: #ffffff; /* Bílá pro nadpisy */
}

.col span {
  display: block;
  font-size: 20px;
  color: #cccccc; /* Světle šedá pro podnadpisy */
}

.col h5 {
  margin-top: 15px;
  line-height: 1.5;
  font-size: 24px;
  font-weight: 300;
  color: #aaaaaa; /* Mírně tmavší šedá pro popisy */
}

.col p {
  margin-top: 20px;
  font-size: 24px;
  font-weight: 300;
  color: #dddddd; /* Jemná světle šedá */
}

/* Media Queries */

/* Pro obrazovky menší než 768px (mobilní zařízení) */
@media (max-width: 768px) {
  #backgroundtext {
    font-size: 32px;
    text-align: center;
    margin: 20px 0;
    color: #ffffff; /* Zachování kontrastu i na menších obrazovkách */
  }
  .col {
    width: 90%;
    margin: 10% auto;
  }
  .col h3 {
    font-size: 18px;
    color: #ffffff;
  }
  .col span {
    font-size: 16px;
    color: #cccccc;
  }
  .col h5,
  .col p {
    font-size: 14px;
    color: #aaaaaa;
  }
}

/* Pro obrazovky mezi 769px a 1024px (tablety) */
@media (min-width: 769px) and (max-width: 1024px) {
  #backgroundtext {
    font-size: 100px;
    margin-bottom: 30px;
    color: #ffffff;
  }
  .col {
    width: 85%;
    margin: 7% auto;
  }
  .col h3 {
    font-size: 24px;
    color: #ffffff;
  }
  .col span {
    font-size: 18px;
    color: #cccccc;
  }
  .col h5,
  .col p {
    font-size: 18px;
    color: #aaaaaa;
  }
}

/* Pro obrazovky větší než 1024px (stolní počítače) */
@media (min-width: 1025px) {
  #backgroundtext {
    font-size: 100px;
    margin-bottom: 50px;
    color: #ffffff;
  }
  .col {
    width: 70%;
    margin: 5% auto;
  }
  .col h3 {
    font-size: 32px;
    color: #ffffff;
  }
  .col span {
    font-size: 20px;
    color: #cccccc;
  }
  .col h5,
  .col p {
    font-size: 24px;
    color: #aaaaaa;
  }
}
